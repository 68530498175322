import React from 'react'
import { Button, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import PaddedFlexCentered from 'components/PaddedFlexCentered'

interface Props {
  onCancel: () => void
  onConfirm: () => void
  isError: boolean
}
const useStyles = makeStyles(theme => ({
  button: {
    marginTop: theme.spacing(1),
  },
}))
export const ResetPasswordSubmitted = (props: Props) => {
  const classes = useStyles()
  const {
    onCancel,
    onConfirm,
    isError,
  } = props
  if(isError) {
    return (
      <>
        <PaddedFlexCentered>
          <Typography component="h1" variant="h5">
            Password Reset Failed
          </Typography>
          <p>
            An error has occurred and your password has not been reset. This could be due to the password reset link expiring.
          </p>
          <p>
            If you would like to resend a new password reset request you can click the resend button below.
          </p>
          <Button
            className={classes.button}
            color="primary"
            fullWidth
            onClick={onConfirm}
            type="button"
            variant="contained"
          >
            Resend
          </Button>
          <Button
            className={classes.button}
            fullWidth
            onClick={onCancel}
            type="button"
            variant="contained"
          >
            Cancel
          </Button>
        </PaddedFlexCentered>
      </>

    )
  } else {
    return (
      <>
        <PaddedFlexCentered>
          <Typography component="h1" variant="h5">
            Password Reset
          </Typography>
          <p>
            Your password has been reset.
          </p>
          <Button
            className={classes.button}
            color="primary"
            fullWidth
            onClick={onCancel}
            type="button"
            variant="contained"
          >
            Return to login
          </Button>
        </PaddedFlexCentered>
      </>

    )
  }
}

export default ResetPasswordSubmitted
